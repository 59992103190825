.footer {
    background-color: #d32f2f; /* Footer background color */
    text-align: center; /* Center text alignment */
    padding: 20px 0; /* Padding above and below */
    position: relative; /* Relative positioning */
    bottom: 0; /* Stick to bottom */
    width: 100%; /* Full width */
}

.footer p {
    margin: 5px 0; /* Margin above and below */
    font-size: 14px; /* Font size for paragraph */
    color: #ffffff !important;
    text-align: center; /* Set paragraph text color to white */
}

.social-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Ensure items wrap when there's not enough space */
    gap: 10px; /* Space between the links */
}

.social-links a {
    color: #ffffff ; /* Set link color to white */
    text-decoration: none; /* No underline for links */
    padding: 10px 20px; /* Add some padding to make the links bigger */
    background-color: rgba(255, 255, 255, 0.1); /* Light background on the links */
    border-radius: 5px; /* Rounded corners for the links */
}

.social-links a:hover {
    color: #007BFF; /* Change color on hover */
    cursor: pointer;
}

.social-links a:focus {
    outline: none; /* Remove outline on focus */
}

/* Media Queries */

/* For mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .footer p {
        font-size: 12px; /* Reduce font size on small screens */
    }

    .social-links {
        justify-content: space-around;
    }

    .social-links a {
        flex: 0 0 45%; /* Make links take up 45% of the container width */
    }
}

/* For tablets like iPads (max-width: 768px) */
@media (max-width: 768px) {
    .footer p {
        font-size: 13px;
        text-align: center; /* Slightly smaller font size on tablets */
    }

    .social-links a {
        flex: 0 0 22%; /* Make links take up 22% of the container width */
    }
}
