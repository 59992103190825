/* Navbar Container */
nav {
  background-color: #2F3E48; /* Dark background color */
  padding: 20px; /* Adjusted padding */
  border-bottom: 3px solid red; /* Red bottom border */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows navbar items to wrap if needed */
}

/* Logo Styling */
.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.logo-red {
  color: red; /* Red color for "VITALISYSHAK" */
}

/* Navbar List */
nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; /* Wrap items when the screen is narrow */
}

/* Navbar List Items */
nav ul li {
  margin: 10px 20px; /* Default margin for larger screens */
}

/* Navbar Links */
nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: red; /* Hover color */
}

/* Active Link */
nav ul li a.active {
  color: red; /* Red color for active link */
}

/* Responsive Behavior for Smaller Screens */
@media (max-width: 768px) {
  nav {
    flex-direction: row;
    align-items: flex-start; /* Align to the left */
    padding: 10px 20px;
  }

  nav ul {
    justify-content: flex-start;
    width: 100%; /* Make the navbar full width */
  }

  nav ul li {
    margin: 8px 15px; /* Adjusted smaller margin for tablet screens */
  }

  nav ul li a {
    font-size: 14px; /* Smaller font size for better fit on smaller screens */
  }
}

/* For very small screens (extra responsiveness) */
@media (max-width: 480px) {
  nav {
    padding: 10px 15px;
  }

  nav ul {
    flex-direction: row;
    justify-content: space-between; /* Spread items on very small screens */
    width: 100%;
    align-items: center; /* Center-align the items on very small screens */
  }

  nav ul li {
    margin: 5px 10px; /* Further reduce the space between items for mobile devices */
  }

  nav ul li a {
    font-size: 12px; /* Smaller font size for tiny screens */
  }
}
