body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    color: #333;
}

.contact-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    color:red;
}

p {
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    color: #555;
}

.contact-info {
    margin: 40px 0;
    text-align: center;
}

.contact-info h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color:red;
}

.contact-info p {
    font-size: 16px;
    margin: 10px 0;
    color: #555;
}

.social-media {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;

}

.social-media li {
    margin: 0 10px;
}

.social-media a {
    color: #d32f2f;
    text-decoration: none;
    font-size: 18px;
}

.social-media a:hover {
    text-decoration:aqua;
}

h2 {
    text-align: center;
    color:red;
    margin-top: 40px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.form-group input, 
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-group input:focus, 
.form-group textarea:focus {
    outline: none;
    border-color: red;
}

button[type="submit"] {
    background-color: red;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
}

button[type="submit"]:hover {
    background-color: #a62828;
}
@media (max-width: 768px) {
  

    .contact-page p {
        font-size: 16px;
        text-align: justify;
    }

}