/* Partner Styles */
#partners {
    padding: 40px 0;
    background-color: #e7e2e2;
    text-align: center;
}

.partner-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.partner {
    width: 30%; /* Default width for larger screens */
    margin-bottom: 40px;
    text-align: center;
}

.partner-circle {
    width: 50%; /* Set width relative to the container */
    max-width: 150px; /* Max width to ensure image doesn’t grow too large */
    aspect-ratio: 1 / 1; /* Ensures the element stays a square */
    border-radius: 50%; /* Circular shape for larger screens */
    overflow: hidden;
    margin: 0 auto 20px;
    position: relative;
}

/* Ensure image fits well inside the circle */
.partner-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* Style for partner titles */
.partner h3 {
    margin-bottom: 10px;
    font-size: 24px;
    color: red;
    font-weight: 700;
}

.partner p {
    font-size: 16px;
    color: #333;
    text-align: justify;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .partner-container {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center;
    }

    .partner {
        width: 100%; /* Full width for each partner on mobile */
        margin-bottom: 20px;
    }

    .partner-circle {
        width: 80%; /* Scale width relative to screen size */
        max-width: 200px; /* Maximum width to ensure the image doesn't grow too large */
        height: auto; /* Let the height adjust automatically to maintain aspect ratio */
        border-radius: 0; /* Remove circular shape for mobile (square shape) */
    }

    .partner-circle img {
        width: 100%; /* Ensure the image fills the square */
        height: auto; /* Automatically adjust height to maintain aspect ratio */
    }

    .partner h3 {
        font-size: 20px;
        color:red; 
        font-weight: 700;/* Slightly smaller title font on mobile */
    }

    .partner p {
        font-size: 14px;
        text-align: justify;
        margin: 20px; /* Slightly smaller text font on mobile */
    }
}
