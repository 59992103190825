/* General Page Styling */
.about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    color: #333;
    font-family: 'Roboto', sans-serif;
    
}

/* Headers Styling */
.about-page h1 {
    font-size: 48px;
    color: red;
    text-align: center !important;
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: none !important;
    border-bottom: 2px solid red;
    display: inline-block;
    padding-bottom: 10px;
}

.about-page h2 {
    font-size: 32px;
    color:red;
    margin-top: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

/* Paragraph Styling */
.about-page p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 30px;
    color: #555;
    text-align: justify;
}

/* Strong Text (highlights) */
.about-page strong {
    color:red;
    font-weight: bold;
}

/* List Styling */
.about-page ul {
    padding-left: 20px;
    margin-bottom: 40px;
}

.about-page ul li {
    font-size: 18px;
    margin-bottom: 10px;
    color: #444;
}

/* Links (Contact Us Link) */
.about-page a {
    color: red;
    text-decoration: none;
    font-weight: bold;
}

.about-page a:hover {
    text-decoration: underline;
    
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-page h1 {
        font-size: 36px;
        text-decoration: none;
    }

    .about-page h2 {
        font-size: 28px;
    }

    .about-page p {
        font-size: 16px;
    }

    .about-page ul li {
        font-size: 16px;
    }
}
