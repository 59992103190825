/* General page styling */
.project-page {
    font-family: 'Arial', sans-serif;
    background-color: #eee;
    color: #333;
    padding: 0;
    margin: 0;
}

/* Main content */
.main-content {
    padding: 40px;
    text-align: center;
    background-color: inherit; /* Ensure it matches the page's background */
}

/* Responsive Image Styling */
.project-image {
    width: 30%; /* Full width for responsiveness */
    height: auto; /* Maintain aspect ratio */
    max-width: 800px;
    margin: auto; /* Limit max width for larger screens */
    margin-bottom: -130px !important; /* Center the image */
    display: block; /* Block element for centered alignment */
    border-radius: 8px; /* Optional: add rounded corners */
}

.main-content p {
    font-size: 17px;
    color: #555;
    margin-top: -10px;
    text-align: center;
}

.info-section h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 25px;
    margin-top: 20px;
}

.info-section p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
}

/* Request button styling */
.request-btn {
    background-color: #d32f2f;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    margin-top: 10px;
}

.request-btn:hover {
    background-color: #b71c1c;
}


/* Responsive styling */
@media (max-width: 768px) {
    .main-content {
        padding: 20px;
    }

    .main-content p {
        font-size: 14px;
        margin-top: 15px;
    }

    .info-section h3 {
        font-size: 20px;
        margin:0px;
    }

    .info-section p,
    .main-content p {
        font-size: 15px;
    }

    .request-btn {
        font-size: 14px;
        padding: 8px 16px;
    }
    .project-image{
        width: 100%;
    }
}
