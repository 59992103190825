.home {
  padding: 20px;
}

.about-us {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically center */
  justify-content: space-between; /* Space out items */
}

.about-content {
  display: flex; /* Use flexbox for the content */
  align-items: center; /* Align image and text vertically center */
  flex-direction: row; /* Default: row for larger screens */
}

.about-image {
  width: 40%; /* Set the width of the image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between the image and text */
}

.about-text {
  max-width: 50%; /* Limit the width of the text */
  text-align: justify; /* Justify the text */
  hyphens: auto; /* Enable automatic hyphenation */
}

/* Style for the "Learn More" button */
.learn-more {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007BFF; /* Button background color */
  color: #ffffff; /* Button text color */
  text-decoration: none;
  border-radius: 5px; /* Round corners */
  text-align: center; /* Centers the text within the button */
}

.learn-more:hover {
  background-color: #0056b3; /* Darken button on hover */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack items vertically on small screens */
    text-align: center; /* Center-align content */
  }

  .about-image {
    width: 100%; /* Full width for the image on mobile */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add margin below the image */
  }

  .about-text {
    max-width: 100%; /* Text takes up full width on mobile */
    text-align: justify; /* Ensure justified text on mobile */
    hyphens: auto; /* Allow words to break properly */
  }

  /* Center the "Learn More" button */
  .learn-more {
    margin-left: auto; /* Add auto margins */
    margin-right: auto;
  }
}
